<template>
  <div id='App' :class='{white : $route.matched.some(({ name }) => (name == "News"))}'>
    <router-view />
  </div>
</template>

<style>
  :root {
    --main-max-width: 110rem; /* 110rem;*/
    --main-padding: 5.25rem 4.25rem 1.75rem;
    --columndirection: row;
    --overlaypadding: 5em 3em;
    --leftcolumnwidth: calc(41.25% - 2rem);
    --rightcolumnwidth: calc(58.75% - 2rem);
    --display-or-not: none;
    --regular: 0.85rem;
    --menuright: 1.5rem;
    --medium: 1.05rem;
    --detailswidth: 50%;
    --columns: 2;
    --rootem: 15px; /* 12px;*/
    --white: rgb(230,230,230);
    --whitebg: rgb(250,250,250);
    --black: rgb(50,50,50);
    --gray: rgb(120,120,120);
    --colortransition: color 300ms linear, fill 300ms linear;
    --bezier: cubic-bezier(0.74,0.11,0.56,1);
    --contactblockwidth: 22.5%;
    --contactblockheight: 27rem;
    --newswidth: 66vw;
    --projectitemwidth: calc( ( 100% / 3 ) - 3rem );
    --projectitemsidemargin: 1.25rem;
    --logoheight: 4rem;
    --detailspadding: 6rem;
    --detailbodypadding: 5rem;
  }

  @media (-webkit-min-device-pixel-ratio: 2){
    :root {
      --thinline: 1px solid var(--gray);
    }
  }

  @media screen and (max-width: 1200px) {
    :root {
      --rootem: 10px;
    }
  }

  @media screen and (max-width: 800px) {
    body:before {
      content: 'phone';
      display: none;
    }
  }

  @media screen and (max-width: 800px) {
    :root {
      --main-max-width: calc(100vw);
      --main-padding: 8rem 2.5rem 1.5rem;
      --columns-or-rows: column;
      --overlaypadding: 5em 2em 10em;
      --display-or-not: none;
      --rootem: 8.5px;
      --regular: 1.5rem;
      --detailspadding: 1rem;
      --columns: 2;
      --detailswidth: 100%;
      --medium: 1.5rem;
      --columndirection: column;
      --leftcolumnwidth: 100%;
      --rightcolumnwidth: 100%;
      --contactblockwidth: 100%;
      --contactblockheight: auto;
      --projectitemwidth: calc( ( 100% / 1 ) - 2rem );
      --newswidth: 95vw;
      --logoheight: 2rem;
      --menuright: 0.75rem;
      --detailbodypadding: 0rem;
    }
  }
</style>

<style>
  @import 'fonts/index.css';

  body {
    font-family: "CourierSansLLWeb-Regular";
    text-align: center;
    font-size: 0.9vw;
    background-color: black;
  }

  body {
    scrollbar-width: thin;
    scrollbar-color: white transparent;
    scrollbar-gutter: stable both-edges;
  }

  body::-webkit-scrollbar {
    width: 5px;
  }

  body::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius: 0;
  }

  @media screen and (min-width: 800px) {
    body:before {
      content: 'default';
      display: none;
    }
  }

  html {
    font-size: var(--rootem);
    height: 100%;
  }

  body {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
  }

  strong {
    font-weight: inherit;
    letter-spacing: inherit;
  }

  h1 {
    margin: auto;
    font-family: 'GzaSemibold';
    font-weight: normal;
    font-size: 5rem;
    letter-spacing: -0.02em;
    line-height: 1.05em;
    display: block;
    text-align: center;
  }

  big {
    font-family: 'GzaSemibold';
    font-weight: 100;
    font-size: 3.33rem;
    letter-spacing: -0.035em;
    line-height: 1.05em;
    max-width: 17em;
    display: block;
    text-align: left;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
  }

  h2 {
    font-family: 'GzaSemibold';
    font-weight: normal;
    font-size: 3.33rem;
    letter-spacing: -0.02em;
    line-height: 1.05em;
    max-width: 17em;
    display: block;
    text-align: left;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
  }

  h3 {
    font-family: 'GzaSemibold';
    font-weight: normal;
    font-size: 2.5rem;
    letter-spacing: -0.02em;
    line-height: 1.05em;
    max-width: 17em;
    display: block;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
  }

  h4 {
    margin-top: 4rem;
    margin-bottom: 2.5rem;
    font-size: var(--regular);
    letter-spacing: 0.03em;
    text-transform: uppercase;
  }

  h5 {
    font-size: var(--regular);
    letter-spacing: 0.03em;
    text-transform: uppercase;
  }

  blockquote {
    font-family: 'GzaSemibold';
    font-weight: normal;
    font-size: 3.33rem;
    letter-spacing: -0.02em;
    line-height: 1.05em;
    max-width: 20em;
    margin: auto;
    margin-bottom: 0.75em;
    display: block;
    text-align: center;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
  }

  h4 {
    font-weight: inherit;
  }


  h5 {
    font-weight: inherit;
  }

  h6 {
    font-weight: inherit;
  }

  input {
    font-family: inherit;
  }

  button {
    font-family: "CourierSansLLWebTrial-Regular";
    background-color: transparent;
    border: none;
    box-sizing: border-box;
    color: inherit;
    font-size: var(--regular);
    font-family: inherit;
  }

  figure {
    padding: inherit;
    margin: inherit;
  }


  p {
    font-size: inherit;
    margin-top: 0;
    margin-bottom: 1.4em;
  }

  big p {
    margin-bottom: 1em;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  u {
    text-decoration: none !important;
    padding-bottom: 0.125em;
    background-position: bottom;
    background-repeat: repeat no-repeat;
    background-image: url('data:image/svg+xml;charset=utf8,\
      <svg xmlns="http://www.w3.org/2000/svg" width="8" height="1" viewBox="0 0 8 1">\
        <rect x="0" y="0" width="8" height="1" fill="%238eccd1"/>\
      </svg>\
    ');
  }

  a.button::after {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 11'%3E%3Cpath fill='rgb(250,250,250)' d='M306,401.43a5.08,5.08,0,1,1,5.07-5.08A5.09,5.09,0,0,1,306,401.43Zm-.86-2.95a.77.77,0,0,0-.22.53.75.75,0,0,0,.22.53.75.75,0,0,0,1.06,0l2.66-2.65a.77.77,0,0,0,0-1.07l-2.66-2.66a.75.75,0,0,0-1.06,0,.77.77,0,0,0,0,1.07l1.38,1.37h-3.22a.75.75,0,0,0,0,1.5h3.22Z' transform='translate(-300.96 -391.28)'/%3E%3C/svg%3E");
    fill: white;
    display: inline-block;
    vertical-align: baseline;
    position: relative;
    top: 0.125em;
    margin-left: 1em;
    width: 1em;
    height: 1em;
  }
</style>

<style>
  div#App {
    transition: background-color 300ms cubic-bezier(0.74,0.11,0.56,1);
    min-height: 100vh;
    z-index: -2;
  }

  div#App:not(.white) {
    background-color: transparent;
    --thinline: 1px solid rgba(255,255,255,0.25);
  }

  div#App.white {
    background-color: var(--whitebg);
    --thinline: 1px solid rgba(0,0,0,0.125);
  }

  div#App:not(.white) a {
    color: var(--white);
  }

  div#App.white a {
    color: var(--black);
  }

  div#App:not(.white) svg {
    fill: var(--white);
  }

  div#App.white svg {
    fill: var(--black);
  }

  div#App:not(.white) main {
    color: var(--white);
    transition: color 1000ms linear;
  }

  div#App.white main {
    transition: color 1000ms linear;
    color: var(--black);
  }
</style>
