/*
Copyright (C) Production Type
This software is the property of Production Type.
Your right to access and use this software is subject to the
applicable License Agreement, or Terms of Service, that exists
between you and Production Type. If no such agreement exists,
you may not access or use this software for any purpose.
This software may only be hosted at the locations specified in
the applicable License Agreement or Terms of Service, and only
for the purposes expressly set forth therein. You may not copy,
modify, convert, create derivative works from or distribute this
software in any way, or make it accessible to any third party,
without first obtaining the written permission of Production Type.
For more information, please visit us at www.productiontype.com
*/

@font-face {
  font-family: "CourierSansLLWeb-Regular";
  src: url("CourierSansLLWeb-Regular.woff") format("woff");
}

@font-face {
  font-family: "CourierSansLLWeb-Regular";
  src: url("CourierSansLLWeb-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "CourierSansLLWeb-Regular";
  font-style: italic;
  src: url("CourierSansLLWeb-Italic.woff") format("woff");
}

@font-face {
  font-family: "CourierSansLLWeb-Regular";
  font-style: italic;
  src: url("CourierSansLLWeb-Italic.woff2") format("woff2");
}

@font-face {
  font-family: "GzaSemibold";
  src: url("GzaSemibold-Web.woff") format("woff");
  src: url("GzaSemibold-Web.woff2") format("woff2");
}
